import React, { ReactChild } from "react";
import { DynamicLine, ExternalLinkIcon } from "../";
import styles from "./Button.module.scss";
import { Link } from "react-router-dom";

export interface ButtonProps {
  children?: ReactChild;
  onClick?: () => any;
  to?: string;
  external?: boolean
}

export function Button({ children, onClick, to, external = false }: ButtonProps) {
  if (external) { 
   return <a className={styles.Button} href={to} rel="noreferrer noopener" target="_blank">
      {children}
      <ExternalLinkIcon size={10} />
      <DynamicLine min={1} max={1} />
      <DynamicLine min={1} max={2} />
    </a>
  }
  if (to != null) {
    return <Link className={styles.Button} to={to}>
      <>
      {children}
      <DynamicLine min={1} max={3} />
      <DynamicLine min={1} max={2} />
      </>
    </Link>
  }
  return (
    <button className={styles.Button} onClick={onClick}>
      {children}
      <DynamicLine min={1} max={3} />
      <DynamicLine min={1} max={2} />
    </button>
  );
}
