import { useState, useLayoutEffect, RefObject } from "react";

export const useOnScreen = (ref: RefObject<HTMLElement | null>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  
  useLayoutEffect(() => {
    if (ref.current === null) return;

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};
