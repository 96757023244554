import React from "react";
import styles from "./Contact.module.scss";

const socialLinks = [
  {
    externalUrl: "https://github.com/krystalcampioni",
    name: "github",
  },
  {
    externalUrl: "https://linkedin.com/in/krystalcampioni",
    name: "linkedin",
  },
  {
    externalUrl: "http://twitter.com/krystalcampioni",
    name: "twitter",
  },
  {
    externalUrl: "http://codepen.io/krystalcampioni/",
    name: "codepen",
  },
];

export default function Contact() {
  return (
      <ul className={styles.Contact}>
        {socialLinks.map((network) => {
          const image =  require(`../../images/social/${network.name}.jpg`);
          return (
            <li key={network.externalUrl}>
              <a
                href={network.externalUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={image} alt={network.name} />
              </a>
            </li>
          );
        })}
      </ul>
  );
}
