interface Props {
  sourceElement: HTMLImageElement;
  x: number;
  y: number;
  width: number;
  height: number;
  currentWidth?: number;
  currentHeight?: number;
  yOffset?: number;
}

export class StaticImage {
  sourceElement: HTMLImageElement;
  x: number;
  y: number;
  width: number;
  height: number;
  yOffset: number;

  currentWidth: number;
  currentHeight: number;
  currentX: number;
  currentY: number;

  constructor({
    sourceElement,
    x,
    y,
    width,
    height,
    yOffset = 0
  }: Props) {
    this.sourceElement = sourceElement;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;

    this.currentWidth = width;
    this.currentHeight = height;
    this.currentX = x;
    this.currentY = y;
    this.yOffset= yOffset;
  }

  public getRatioToFullSize() {
    return window.innerWidth / this.sourceElement.naturalWidth;
  }

  public updateSize({ currentWidth = this.currentWidth, currentHeight = this.currentHeight}) {
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;
  }

  public updatePosition({ currentX = this.currentX, currentY = this.currentY }) {
    this.currentX = currentX;
    this.currentY = currentY;
  }

  public getFullScreenSize() {
    const { naturalWidth, naturalHeight } = this.sourceElement;
    const ratio = this.getRatioToFullSize();

    return {
      width: naturalWidth * ratio,
      height: naturalHeight * ratio,
    };
  }
}
