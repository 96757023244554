import React from "react";
import styles from "./SwimmingKrystal.module.scss";

export function SwimmingKrystal() {
  return (
    <picture className={styles.SwimmingKrystal}>
      <source type="image/webp" srcSet="/krystal.webp" />
      <img
        src="/krystal.png"
        alt="Drawing of Krystal in a diving suit, floating underwater"
      />
    </picture>
  );
}
