import {useState, useEffect} from 'react';
import {ResizeObserver as Polyfill} from '@juggle/resize-observer';

const ResizeObserver = window.ResizeObserver || Polyfill;

export interface Dimensions {
  top: number;
  left: number;
  width: number;
  height: number;
  offsetTop: number;
}

const getElementSize = (element: React.RefObject<HTMLElement | null>) => {
  if (element.current == null || !(element.current instanceof Element)) {
    return null;
  }

  const {left, width, height, top} = element.current.getBoundingClientRect();
  const {offsetTop} = element.current;

  return {
    top,
    left,
    width,
    height,
    offsetTop,
  };
};

export function useElementSize(
  element: React.RefObject<HTMLElement | null>,
): Dimensions | null {
  const [componentSize, setComponentSize] = useState(getElementSize(element));

  // Ensure we get the element size on mount
  useEffect(() => {
    setComponentSize(getElementSize(element));
  }, [element]);

  useEffect(() => {
    if (!element.current) {
      return;
    }
    const handleResize = () => setComponentSize(getElementSize(element));

    const observer = new ResizeObserver(handleResize);
    observer.observe(element.current);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return componentSize;
}
