import {easeLinear} from 'd3-ease';

// const FIVE_MINUTES = 5 * 60 * 1000;
// const ELEVEN_SECONDS = 11 * 1000;

// export const WORLD_VIEW_REGION_ID = 'world';
// export const POLLING_INTERVAL = 10_000;
// export const BREAKPOINT_MOBILE = 802;
// export const BREAKPOINT_TABLET = 1024;
// export const BREAKPOINT_DESKTOP = 1340;

// export const TIME_BASED_SPRING_CONFIG = {
//   duration: POLLING_INTERVAL * 0.7,
//   easing: easeLinear,
// };

export const ARC_START_ANGLE = 0;
export const ARC_END_ANGLE = Math.PI * 2;
export const IS_COUNTER_CLOCKWISE = false;

// export const INNER_RADIUS = 10;
// export const OUTER_RADIUS = 20;

// export const SPRITE_ANIMATION_LENGTH = 40;
// export const SPRITE_CANVAS_WIDTH = OUTER_RADIUS * 2 * SPRITE_ANIMATION_LENGTH;

export const LEVEL_HEIGHT = 290;
export const TRANSITION_DURATION = 1500;