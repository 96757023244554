import React from "react";

import { Contact as SocialLinks, SwimmingKrystal } from "../../components";

import styles from "./Contact.module.scss";

export function Contact() {
  return (
    <div className={styles.Contact}>
      <div className={styles.SharkWrapper}>
        <div className={styles.Shark} />
      </div>
      <div className={styles.ContentWrapper}>
        <h1>Contact</h1>
        <article>
          <p>Let's have some coffee together? </p>
          <p>You can find me on:</p>
          <SocialLinks />
        </article>
      </div>

      <div
        style={{
          transform: `scale(-1,1)`,
        }}
      >
        <SwimmingKrystal />
      </div>
    </div>
  );
}
