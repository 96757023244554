export class AnimationClock {
  elapsedTime: undefined | number;
  duration: number;
  private startTime: number;
  private running: boolean;
  private elapsed: number;

  constructor(duration = 1000) {
    this.duration = duration;
    this.startTime = 0;
    this.running = false;
    this.elapsed = 0;
  }

  public start() {
    this.startTime = new Date().getTime();
    this.elapsedTime = undefined;
    this.running = true;
  }

  public stop() {
    this.elapsed = new Date().getTime() - this.startTime;
    this.running = false;
  }

  public getElapsedTime() {
    if (this.running) {
      return new Date().getTime() - this.startTime;
    } else {
      return this.elapsed;
    }
  }

  public isRunning() {
    return this.running;
  }

  public isOver() {
    return this.getElapsedTime() > this.duration;
  }

  public reset() {
    this.elapsed = 0;
  }
}
