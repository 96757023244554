import React, { useRef, useState, useLayoutEffect } from "react";
import { useGesture } from "react-use-gesture";
import {
  loadImage,
  drawImage,
  randomNumber,
  AnimatedBubble,
} from "../../utilities";
import bubblePath from "../../images/bubble.png";
import { useAnimate } from "../../hooks";

import styles from "./Bubbles.module.scss";

export interface BubblesProps {
  width: number;
  height: number;
}

const FRAME_WIDTH = 242;

export function Bubbles({ width, height }: BubblesProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [bubbleImage, setBubbleImage] = useState<HTMLImageElement | null>(null);
  const bubbles = useRef<AnimatedBubble[]>([]);

  useLayoutEffect(() => {
    const loadImages = async () => {
      const bubbleImage = await loadImage(bubblePath);
      setBubbleImage(bubbleImage);
    };

    loadImages();
  }, []);

  useGesture(
    {
      onClick: (state) => {
        const context = canvasRef.current?.getContext("2d");
        const event = state.event as unknown as React.MouseEvent
        
        if (!context || !bubbleImage) return;
        const x = event.clientX * 2;
        const y = event.clientY * 2;

        const newBubbles = Array(4)
          .fill(null)
          .map((_, index) => {
            const newBubble = new AnimatedBubble({
              frameWidth: FRAME_WIDTH,
              sourceElement: bubbleImage,
              x: randomNumber(x - 40, x + 40),
              y: randomNumber(y - 40, y + 40),
              width: 3759,
              height: 226,
              spriteAnimationLength: 16,
              timeToLive: randomNumber(2000,2500),
              delay: randomNumber(80, 100) * index,
            });
            newBubble.start();
            return newBubble;
          });
        // bubbles.current.push(...newBubbles);
        bubbles.current = [...bubbles.current.filter(b => b.animation.isRunning()), ...newBubbles]
      },
    },
    { domTarget: window }
  );

  useAnimate(() => {
    const context = canvasRef.current?.getContext("2d");

    if (bubbleImage == null || !context) return;
    context.clearRect(0, 0, width * 2, height * 2);

    bubbles.current.forEach((bubble, index) => {
      const sourceY = 0;
      const currentFrame = bubble.getFrame();
      const sourceX = currentFrame * FRAME_WIDTH;

      if (bubble.animation.isOver()) return;
      const { x, y } = bubble.getPosition();

      drawImage({
        context,
        degrees: 0,
        isFlipped: false,
        source: bubbleImage,
        sourceX: sourceX,
        sourceY: sourceY,
        sourceWidth: FRAME_WIDTH,
        sourceHeight: bubbleImage.height,
        x,
        y, // + animatedY * 2,
        projectedWidth: bubble.getRadius(),
        projectedHeight: bubble.getRadius(),
      });
    });
  });

  return (
    <canvas
      ref={canvasRef}
      className={styles.Bubbles}
      width={width * 2}
      height={height * 2}
    />
  );
}
