import React, { useMemo } from "react";
import projectsData from "../../data/projectsData.json";
import { ListItem, Button } from "../../components/";

import styles from "./Projects.module.scss";

interface Project {
  featured?: boolean;
  title: string;
  description: string;
  slug: string;
  externalLink: string;
  externalLinkText: string;
}

export function ProjectsSkeleton() {
  const skeletonProjects = new Array(3).fill(null)
  return (
    <div className={styles.Projects}>
      <h1 className="SectionTitle">PROJECTS</h1>
      <ul>
        {skeletonProjects.map((_, index) => {
          return (
            <li key={index}>
              <div className='imagePlaceholder'></div>
              <div className='textPlaceholder'>
                <span/>
                <span/>
                <span/>
              </div>
            </li>
          );
        })}
      </ul>
      <div className={styles.CTA}>
        <p>
          See more projects on{" "}
          <Button external to="https://github.com/krystalcampioni">
            my Github
          </Button>
        </p>
        <p>
          If we went together this far, maybe we should{" "}
          <Button to="/contact">have a chat</Button>?
        </p>
      </div>
    </div>
  );
}
