import { scaleLinear, ScaleLinear } from "d3-scale";
import { easeCubicIn as easeIn, easeCubicOut as easeOut } from "d3-ease";
import { AnimatedSprite, AnimatedSpriteProps, AnimationClock } from "./";

interface AnimatedBubbleProps extends AnimatedSpriteProps {
  timeToLive: number;
  delay: number;
}

const MAX_RADIUS = 60;
export class AnimatedBubble extends AnimatedSprite {
  private radiusScale: ScaleLinear<number, number>;
  private delay: number;

  constructor({
    timeToLive,
    x,
    y,
    spriteAnimationLength = 15,
    sourceElement,
    width,
    height,
    frameWidth,
    delay,
  }: AnimatedBubbleProps) {
    super({
      x,
      y,
      spriteAnimationLength,
      sourceElement,
      width,
      height,
      frameWidth,
    });
    this.delay = delay;
    this.animation = new AnimationClock(timeToLive + delay);
    this.radiusScale = scaleLinear()
      .domain([0, 1])
      .range([0, MAX_RADIUS])
      .clamp(true);
  }

  public start() {
    setTimeout(() => {
      this.animation.start()
    }, this.delay);
  }

  public getNormalizedTime(min: number, max: number) {
    const time = this.animation.getElapsedTime();
    return (time - min) / (max - min);
  }

  public getPosition() {
    this.x-=1;
    this.y-=3;
    return {
      x: this.x,
      y: this.y,
    };
  }

  public getRadius() {
    const time = this.animation.getElapsedTime();
    const ttl = this.animation.duration;
    const enter = ttl * .3
    const leave = ttl * .5

    if (time < enter) {
      const normalizedTime = this.getNormalizedTime(0, enter);
      return this.radiusScale(easeIn(normalizedTime));
    }
    if (time > ttl - leave) {
      const normalizedTime = this.getNormalizedTime(
        ttl - leave,
        ttl
      );
      return this.radiusScale(1 - easeOut(normalizedTime));
    }
    return MAX_RADIUS;
  }
}
