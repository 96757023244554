import React from "react";

import styles from "./Loading.module.scss";

export function Loading() {
  return (
    <div className={styles.Loading}>
      <div>
        <p>Loading</p>
        <div className={styles.Spinner}>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
