import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { TRANSITION_DURATION } from "./constants";

import logo from "./images/logo.png";
import "./App.scss";

import { useDidUpdateEffect } from "./hooks";
import { clamp } from "./utilities";
import { Menu, Bubbles } from "./components";
import { Home, Resume, Speaking, Contact } from "./sections";

const Routes = () => {
  const location = useLocation();

  const transitions = useTransition(location, {
    initial: false,
    from: { opacity: 0 },
    enter: { opacity: 1, delay: TRANSITION_DURATION * 0.8 },
    leave: { opacity: 0, delay: TRANSITION_DURATION * 0.2 },
    keys: (item) => item.pathname,
    onRest: ()  => window.scrollTo(0, 0)
  });

  const [showTransitionWave, setShowTransitionWave] = useState(false);

  useDidUpdateEffect(() => {
    setShowTransitionWave(true);

    setTimeout(() => {
      setShowTransitionWave(false);
    }, TRANSITION_DURATION);
  }, [location]);

  const transitionWidth = 600;
  const transitionHeight = 350;

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])

  const [width, height] = windowSize;

  const ratioToFullWidth = clamp(
    width / transitionWidth,
    width / transitionWidth,
    width / transitionWidth
  );
  const ratioToFullHeight = height / transitionHeight;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize",handleResize)
  }, [])

  
  return (
    <div>
      {showTransitionWave && (
        <div
          className={"Transition"}
          style={{
            width: `${transitionWidth}px`,
            height: `${transitionHeight}px`,
            transform: `scale(${ratioToFullWidth},${ratioToFullHeight})`,
            animationDuration: ` ${TRANSITION_DURATION}ms`,
          }}
        ></div>
      )}
      <Bubbles width={width} height={height}/>
      {transitions(({ opacity }, item) => (
        <animated.div
          style={{
            opacity,
          }}
          className='PageWrapper'
        >
          <Switch location={item}>
            <Route exact path="/" component={Home} />
            <Route exact path="/resume" component={Resume} />
            <Route exact path="/speaking" component={Speaking as any} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </animated.div>
      ))}
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Helmet title="Krystal Campioni - Underwater Portfolio" />
        <div className="App__Header">
          <Link to="/">
            <img width='158' height='67' src={logo} alt="logo" />
          </Link>
          <Menu />
        </div>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
