import React from "react";

import styles from "./ExternalLinkIcon.module.scss";

export interface ExternalLinkIconProps {
  size?: number;
}

export function ExternalLinkIcon({
  size = 24
}: ExternalLinkIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className={styles.ExternalLinkIcon}
    >
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14L21 3"></path>
    </svg>
  );
}
