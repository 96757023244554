import React, {useRef} from "react";
import { randomNumber } from "../../utilities";
import styles from "./DynamicLine.module.scss";

export function DynamicLine({
  min = 1,
  max = 5,
}) {
  const numbers = useRef([
    randomNumber(min, max),
    randomNumber(min, max),
    randomNumber(min, max)
  ])
  
  const path = `
    M 0 ${numbers.current[0]}
    S 2,-2 50,${numbers.current[1]}
    S 100,5 200,${numbers.current[2]}
  `;

  return (
    <svg className={styles.DynamicLine} viewBox="0 -1 200 10">
      <path d={path} strokeMiterlimit="10" />
    </svg>
  );
}
