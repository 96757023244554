import React from "react";
import { useMediaLayout } from "../../hooks";
import { VideoThumbnail } from "../";
import { ExternalLinkIcon } from "../ExternalLinkIcon";

export interface ListItemProps {
  title: string;
  subtitle?: string;
  imageLink?: string;
  image: string;
  preview: string;
  date?: string;
  link?: string;
  content?: string;
  linkDescription?: string;
  secondaryLink?: string;
  secondaryLinkDescription?: string;
  showImages?: boolean;
}

export function ListItem({
  title,
  subtitle,
  imageLink,
  image,
  preview,
  date,
  link,
  linkDescription,
  secondaryLink,
  secondaryLinkDescription,
  content,
  showImages = false,
}: ListItemProps) {
  const mobileWidth = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--mobile-width");
  const isSmallScreen = useMediaLayout(`(max-width: ${mobileWidth})`);

  return (
    <li key={title}>
      <h2>{title}</h2>

      {!isSmallScreen && showImages && imageLink && (
        <VideoThumbnail link={imageLink} picture={image} preview={preview} />
      )}

      {date && <time>{date}</time>}
      {subtitle && <h3>{subtitle}</h3>}
      {content && <p>{content}</p>}

      {secondaryLinkDescription && secondaryLink && !isSmallScreen && (
        <a rel="noreferrer noopener" target="_blank" href={secondaryLink}>
          {secondaryLinkDescription}
          <ExternalLinkIcon size={10} />
        </a>
      )}

      {linkDescription && link && (
        <a rel="noreferrer noopener" target="_blank" href={link}>
          {linkDescription}
          <ExternalLinkIcon size={10} />
        </a>
      )}
    </li>
  );
}
