import {AnimationClock} from './animation-clock';

export interface AnimatedSpriteProps {
  x: number;
  y: number;
  spriteAnimationLength?: number;
  sourceElement: HTMLImageElement;
  width: number;
  height: number;
  frameWidth: number;
}

export class AnimatedSprite {
  animation: AnimationClock;
  x: number;
  y: number;
  sourceElement: HTMLImageElement;
  width: number;
  height: number;
  frameWidth: number;
  private frameIterationCount: number;
  private frame: number;
  private spriteAnimationLength: number;
  private forward: boolean;

  constructor({
    x,
    y,
    spriteAnimationLength = 15,
    sourceElement,
    width,
    height,
    frameWidth
  }: AnimatedSpriteProps) {
    this.sourceElement = sourceElement;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.animation = new AnimationClock();
    this.frame = 0;
    this.frameIterationCount = 0;
    this.spriteAnimationLength = spriteAnimationLength;
    this.forward = true
    this.frameWidth = frameWidth
  }

  public getFrame() {
    if (this.frame === this.spriteAnimationLength - 1) {
      this.forward = false;
      this.frameIterationCount++;
    }
    if (this.frame === 0) {
      this.forward = true
      this.frameIterationCount++;
    }
    if (this.forward) {
      this.frame++;
    } 
    if(!this.forward) {
      this.frame--;
    }
    return this.frame;
  }

  public getFrameIterationCount() {
    return this.frameIterationCount;
  }
}
