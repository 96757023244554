import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ExternalLinkIcon } from "../ExternalLinkIcon";

import styles from "./Menu.module.scss";

export function Menu() {
  const WIDTH = 50;
  const HEIGHT = 50;

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const tabIndex = isOpen ? 0 : -1;

  return (
    <>
      <button
        className={styles.Button}
        aria-label="Menu"
        onClick={toggleIsOpen}
      >
        <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
          <rect
            style={{
              transform: isOpen
                ? `rotate(45deg) translate(-2px, 21px)`
                : "translate(0, 5px)",
            }}
            x={0}
            y={0}
            width={WIDTH}
            height={HEIGHT / 3 - 10}
            rx="4"
          />
          <rect
            style={{
              transform: isOpen
                ? `rotate(45deg) translate(-2px, 11px)`
                : "translate(0, 10px)",
            }}
            x={5}
            y={10}
            width={WIDTH - 5}
            height={HEIGHT / 3 - 10}
            rx="4"
          />
          <rect
            style={{
              transform: isOpen
                ? `rotate(-45deg) translate(0px, 0px)`
                : "translate(0, 15px)",
            }}
            x={0}
            y={20}
            width={WIDTH}
            height={HEIGHT / 3 - 10}
            rx="4"
          />
        </svg>
        <span>Menu</span>
      </button>

      <div className={`${styles.Menu} ${isOpen ? styles.isOpen : ""}`}>
        <nav className={styles.Nav}>
          <ul>
            <li>
              <Link tabIndex={tabIndex} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link tabIndex={tabIndex} to="/resume">
                Resume
              </Link>
            </li>
            <li>
              <Link tabIndex={tabIndex} to="/speaking">
                Speaking
              </Link>
            </li>
            <li>
              <a
                tabIndex={tabIndex}
                rel="noreferrer noopener"
                target="_blank"
                href="https://medium.com/@krystalcampioni"
              >
                Blog <ExternalLinkIcon />
              </a>
            </li>
            <li>
              <a
                tabIndex={tabIndex}
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.artstation.com/krystalcampioni"
              >
                3D/2D <ExternalLinkIcon />
              </a>
            </li>
            <li>
              <Link tabIndex={tabIndex} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
