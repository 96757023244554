import { useCallback, useRef, useLayoutEffect } from "react";
import { AnimatedSprite, loadImage, drawImage } from "../utilities";
import fish1Path from "../images/fish1.png";
import fish2Path from "../images/fish2.png";
import fish3Path from "../images/fish3.png";
import fish4Path from "../images/fish4.png";
import deepFishPath from "../images/deep_fish.png";
import { useSprings } from "react-spring";
import {easeCubicOut, easeLinear} from 'd3-ease'

interface Props {
  context: CanvasRenderingContext2D | null | undefined;
  width: number;
  height: number;
  animatedY:number
}

export function useAnimatedFish({ context, width, height, animatedY }: Props) {
  const fish = useRef<{
    [key: string]: AnimatedSprite;
  } | null>(null);

  useLayoutEffect(() => {
    const loadImages = async () => {
      const fishImages = await Promise.all([
        loadImage(fish1Path),
        loadImage(fish2Path),
        loadImage(fish3Path),
        loadImage(fish4Path),
        loadImage(deepFishPath),
      ]);

      const frameWidths = [241, 116, 123, 101, 395];

      const [fish1, fish2, fish3, fish4, deepFish] = fishImages.map(
        (image, index) =>
          new AnimatedSprite({
            frameWidth: frameWidths[index],
            sourceElement: image,
            x: 0,
            y: 0,
            width: image.width,
            height: image.height,
            spriteAnimationLength: 15,
          })
      );
      fish.current = {
        fish1,
        fish2,
        fish3,
        fish4,
        deepFish
      };
    };

    loadImages();
  }, [fish]);

  const OFFSCREEN_MARGIN = 100;

  const getFishConfigs = useCallback(
    () => [
      {
        source: fish.current?.fish1,
        projectedWidth: 70,
        projectedHeight: 50,
        isFlipped: false,
        animation: {
          steps: [
            {
              x: width + OFFSCREEN_MARGIN,
              y: height/2,
              degrees: -10,
            },
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height/0.8,
              degrees: 30,
            },
          ],
          config: { 
            duration: 10000,
            easing: easeLinear
          },
          delay: 0,
        },
      },
      {
        source: fish.current?.fish1,
        projectedWidth: 70,
        projectedHeight: 50,
        isFlipped: false,
        animation: {
          steps: [
            {
              x: width + OFFSCREEN_MARGIN,
              y: height/3,
              degrees: -10,
            },
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height/0.9,
              degrees: 20,
            },
          ],
          config: { 
            duration: 10000,
            easing: easeLinear
          },
          delay: 500,
        },
      },
      {
        source: fish.current?.fish1,
        projectedWidth: 70,
        projectedHeight: 50,
        isFlipped: false,
        animation: {
          steps: [
            {
              x: width + OFFSCREEN_MARGIN,
              y: height/3,
              degrees: -10,
            },
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height/0.9,
              degrees: 20,
            },
          ],
          config: { 
            duration: 10000,
            easing: easeCubicOut
          },
          delay: 780,
        },
      },
      {
        source: fish.current?.fish2,
        projectedWidth: 70,
        projectedHeight: 50,
        isFlipped: true,
        animation: {
          steps: [
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height/3,
              degrees: -10,
            },
            {
              x: width * 0.8,
              y: 0- OFFSCREEN_MARGIN,
              degrees: -20,
            },
          ],
          config: { 
            duration: 15000,
            easing: easeCubicOut
          },
          delay: 1800,
        },
      },
      {
        source: fish.current?.fish2,
        projectedWidth: 60,
        projectedHeight: 40,
        isFlipped: true,
        animation: {
          steps: [
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height/2,
              degrees: 0,
            },
            {
              x: width * 0.9,
              y: 0- OFFSCREEN_MARGIN,
              degrees: -40,
            },
          ],
          config: { 
            duration: 12000,
            easing: easeCubicOut
          },
          delay: -500,
        },
      },
      {
        source: fish.current?.fish3,
        projectedWidth: 50,
        projectedHeight: 30,
        isFlipped: true,
        animation: {
          steps: [
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height * 0.1,
              degrees: 40,
            },
            {
              x: width + OFFSCREEN_MARGIN,
              y: 0 - OFFSCREEN_MARGIN,
              degrees: 10,
            },
          ],
          config: { 
            duration: 12000,
            easing: easeLinear
          },
          delay: 0,
        },
      },
      {
        source: fish.current?.fish4,
        projectedWidth: 70,
        projectedHeight: 50,
        isFlipped: true,
        animation: {
          steps: [
            {
              x: 0 - OFFSCREEN_MARGIN,
              y: height * 0.3,
              degrees: 40,
            },
            {
              x: width + OFFSCREEN_MARGIN,
              y: 0 - OFFSCREEN_MARGIN,
              degrees: 10,
            },
          ],
          config: { 
            duration: 12000,
            easing: easeLinear
          },
          delay: 500,
        },
      },
      {
        source: fish.current?.deepFish,
        projectedWidth: 350,
        projectedHeight: 250,
        isFlipped: true,
        animation: {
          steps: [
            {
              x: width + OFFSCREEN_MARGIN,
              y: height * 2,
              degrees: 0,
            },
            {
              x: 0 - (OFFSCREEN_MARGIN * 2),
              y: (height * 2) - 200,
              degrees: 0,
            },
          ],
          config: { 
            duration: 16000,
            easing: easeLinear
          },
          delay: 500,
        },
      },
    ],
    [width, height]
  );

  const springs = useSprings(
    getFishConfigs().length,
    getFishConfigs().map((fish, index) => ({
      from: fish.animation.steps[0],
      to: fish.animation.steps[1],
      loop: true,
      delay: fish.animation.delay || 0,
      config: fish.animation.config,
    }))
  );

  const drawFish = useCallback(() => {
    if (!fish.current || !context) return;
    const fishConfigs = getFishConfigs();
    fishConfigs.forEach((fish, index) => {
      const fishSprite = fish.source;

      if (fishSprite == null) return;

      const sourceY = 0;
      const currentFrame = fishSprite.getFrame();
      const sourceX = currentFrame * fishSprite.frameWidth;

      drawImage({
        context,
        degrees: springs[index].degrees.get(),
        isFlipped: fishConfigs[index].isFlipped || false,
        source: fishSprite.sourceElement,
        sourceX: sourceX,
        sourceY: sourceY,
        sourceWidth: fishSprite.frameWidth,
        sourceHeight: fishSprite.height,
        x: springs[index].x.get(),
        y: springs[index].y.get() + animatedY * 2,
        projectedWidth: fishConfigs[index].projectedWidth,
        projectedHeight: fishConfigs[index].projectedHeight,
      });
    });
  }, [context, getFishConfigs, springs]);

  return {
    drawFish,
  };
}
