import { SpringValue } from "@react-spring/core";

export const drawImage = ({
  context,
  degrees,
  isFlipped = false,
  source,
  sourceX,
  sourceY,
  sourceWidth,
  sourceHeight,
  x,
  y,
  projectedWidth,
  projectedHeight,
  opacity = 1
}: {
  context: CanvasRenderingContext2D;
  degrees: number;
  isFlipped?: boolean;
  source: HTMLImageElement;
  sourceX: number;
  sourceY: number;
  sourceWidth: number;
  sourceHeight: number;
  x: number;
  y: number;
  projectedWidth: number;
  projectedHeight: number;
  opacity?: number | SpringValue<number>;
}) => {
  let scaleX = 1;
  const scaleY = 1;
  const centerX = projectedWidth / 2;
  const centerY = projectedHeight/ 2;

  if (isFlipped) {
    scaleX = -1;
  }

  context.save();
  context.globalAlpha = typeof opacity === "number" ? opacity : opacity.get();
  context.translate( x, y );
  context.rotate((degrees * Math.PI) / 180);
  context.scale(scaleX, scaleY);

  context.drawImage(
    source,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    -centerX,
    -centerY,
    projectedWidth,
    projectedHeight
  );
  context.restore();
};
