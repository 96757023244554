import { useEffect, useRef } from "react";

export const useAnimate = (callback: any, fps  = 24) => {
  const requestRef = useRef<number | null>(null);
  const previousTimeRef = useRef<number>();

  useEffect(() => {
    const animate = (time: number) => {
      if (!previousTimeRef.current || time - previousTimeRef.current >= fps) {
        previousTimeRef.current = time;
        callback(time);
      }
      requestRef.current = requestAnimationFrame(animate);
    };
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  }, [callback, fps]);
};
