import React from "react";
import { animated, useSpring, config } from "react-spring";
import { randomNumber } from "../../utilities";

import styles from "./VideoThumbnail.module.scss";
export interface VideoThumbnailProps {
  picture: string;
  preview: string;
  link: string;
}

function AnimatedSquare({ color }: { color: string }) {
  const height = 270;
  const width = 145;
  const animation_margin = 10;

  const minX = height - animation_margin;
  const minY = width - animation_margin;
  const range = [-5, 5];

  const coordinates = [
    [0, 0],
    [0, minY + 0],
    [minX + 0, minY + 0],
    [minX + 0, 0],
  ];

  const generateRandomPoints = () => {
    return coordinates.map(([x, y]) => [
      x + randomNumber(range[0], range[1]),
      y + randomNumber(range[0], range[1]),
    ]);
  };

  const initial = generateRandomPoints();

  const getPath = (XYpositions: any) => `
    M ${XYpositions[0][0]} ${XYpositions[0][1]}
    L ${XYpositions[1][0]} ${XYpositions[1][1]}
    L ${XYpositions[2][0]} ${XYpositions[2][1]}
    L ${XYpositions[3][0]} ${XYpositions[3][1]}
    z
    `;

  const { path } = useSpring({
    default: {
      loop: { reverse: true },
    },
    from: { path: getPath(initial) },
    to: async (next) => {
      await next({
        path: getPath(generateRandomPoints()),
        config: { duration: randomNumber(3000, 3500) },
      });
      await next({
        path: getPath(generateRandomPoints()),
        config: { duration: randomNumber(3000, 3500) },
      });
    },
  });

  return (
    <svg viewBox={`-10 -10 ${height + 10} ${width + 10}`}>
      <animated.path
        strokeMiterlimit={10}
        d={path}
        style={{
          stroke: color,
          strokeWidth: "1px",
        }}
      />
    </svg>
  );
}

export function VideoThumbnail({
  picture,
  preview,
  link,
}: VideoThumbnailProps) {
  return (
    <a
      rel="noreferrer noopener"
      target="_blank"
      href={link}
      className={styles.VideoThumbnail}
    >
      <AnimatedSquare color="var(--text-highlight-color)" />
      <AnimatedSquare color="var(--text-highlight-color)" />

      <picture className={styles.Picture}>
        <img src={picture} alt="" />
        <img src={preview} alt="" loading="lazy" />
      </picture>
    </a>
  );
}
