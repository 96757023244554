import React from "react";
import { Button } from "../";

import styles from "./Intro.module.scss";

export interface IntroProps {
  goToLevel: (level: number) => void;
}

export function Intro({ goToLevel }: IntroProps) {
  return (
    <article className={styles.Intro}>
      <h1>
        Hi, I'm
        <br /> Krystal 👋🏽
      </h1>
      <p>I'm a creative coder currently working at Shopify.</p>
      <p>
        Oh, you might have noticed that I'm a bit obsessed with underwater life!
        Let's dive deeper and check out some of the{" "}
        <Button onClick={() => goToLevel(1)}>
          latest conferences I spoke at?
        </Button>
      </p>
    </article>
  );
}
