/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useWheel, useDrag } from "react-use-gesture";
import { Background, Speaking, Intro, Projects } from "../../components";

import { useElementSize, useMediaLayout } from "../../hooks";

import styles from "./Home.module.scss";
import { LEVEL_HEIGHT } from "../../constants";

export function Home() {
  const mobileWidth = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--mobile-width");
  const isSmallScreen = useMediaLayout(`(max-width: ${mobileWidth})`);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const level0 = useRef<HTMLDivElement>(null);
  const level1 = useRef<HTMLDivElement>(null);
  const level2 = useRef<HTMLDivElement>(null);

  const [pageState, setPageState] = useState({
    level: 0,
    y: 0,
  });

  const { width, height } = useElementSize(wrapperRef) || {
    width: window.innerHeight,
    height: window.innerHeight,
  };

  const goToLevel = (level: number) => {
    switch (level) {
      case 0:
        setPageState({
          y: 0,
          level: 0,
        });
        break;
      case 1:
        setPageState({
          y: -LEVEL_HEIGHT,
          level: 1,
        });
        break;
      case 2:
        setPageState({
          y: -LEVEL_HEIGHT * 2,
          level: 2,
        });
        break;

      default:
        break;
    }
  };

  const handleScroll = ({ delta, event }: any) => {
    event.preventDefault();
    if (animatedY.isAnimating) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_deltaX, deltaY] = delta;
    if (deltaY < 0 && pageState.level < 2) {
      return goToLevel(pageState.level + 1);
    }
    if (deltaY > 0 && pageState.level > 0) {
      return goToLevel(pageState.level - 1);
    }
  };

  useWheel(
    ({ delta, event }) => {
      handleScroll({ delta: [delta[0], delta[1] * -1], event });
    },
    {
      domTarget: wrapperRef,
      eventOptions: { passive: false },
    }
  );
  useDrag(handleScroll, {
    domTarget: wrapperRef,
    eventOptions: { passive: false },
  });

  useEffect(() => {
    const handleKeyUp = ({ key }: KeyboardEvent) => {
      if (key !== "Tab") return;
      console.log(document.activeElement);

      if (level0.current?.contains(document.activeElement)) {
        return goToLevel(0);
      }
      if (level1.current?.contains(document.activeElement)) {
        return goToLevel(1);
      }
      if (level2.current?.contains(document.activeElement)) {
        return goToLevel(2);
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const { animatedY, level: animatedLevel } = useSpring({
    animatedY: pageState.y,
    level: pageState.level,
    config: { mass: 1, tension: 280, friction: 80 },
  });

  const { godrayTime } = useSpring({
    from: { godrayTime: 0.5 },
    to: { godrayTime: 0.8 },
    config: {
      duration: 5000,
    },
    loop: {
      reverse: true,
    },
  });

  return (
    <div
      className={styles.Home}
      ref={wrapperRef}
      style={{ touchAction: "none" }}
    >
      <Background
        y={animatedY}
        width={width}
        height={height}
        godrayTime={godrayTime}
      />
      <animated.main
        style={{
          transform: animatedLevel.to(
            (level) => `translateY(${level * height * -1}px)`
          ),
        }}
      >
        <div ref={level0} className={styles.IntroWrapper}>
          <Intro goToLevel={goToLevel} />
        </div>

        <div ref={level1}>
          <Speaking goToLevel={goToLevel} />
        </div>

        <div ref={level2}>
          <Projects />
        </div>
      </animated.main>

      <animated.div
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: 0,
          transform: animatedY.to((value) => {
            const offset = isSmallScreen ? 190 : 0;
            return `translateY(${value * 5 + offset}px)`;
          }),
        }}
      >
        <picture className={styles.SwimmingKrystal}>
          <source type="image/webp" srcSet="/krystal.webp" />
          <img
            src="/krystal.png"
            alt="Drawing of Krystal in a diving suit, floating underwater"
          />
        </picture>
      </animated.div>
    </div>
  );
}
